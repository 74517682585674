export const MenuItems =[
    {
        title: 'Projects',
        url: '/projects',
        cName: 'nav-links',

    },
    {
        title: 'About',
        url: '/about',
        cName: 'nav-links',

    },
    {
        title: 'Dev.to',
        url: 'https://dev.to/xzirezhs',
        cName: 'nav-links',

    },
]